import sendSlackErrors from "../../../helpers/sendErrorsSlack";

export const SET_ERROR_LOGS = "SET_ERROR_LOGS";
export const CLEAR_ERROR_LOGS = "CLEAR_ERROR_LOGS";

export const setErrorLogs = (error) => async (dispatch) => {
  sendSlackErrors(error);

  // dispatch({
  //   type: SET_ERROR_LOGS,
  //   payload: {
  //     errorLogs: error.stack,
  //     errorData: error.data,
  //   },
  // });
};

export const clearErrorLogs = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERROR_LOGS,
    payload: {},
  });
};
