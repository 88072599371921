import { auth, auth2 } from "../firebase";
import { setErrorLogs } from "../store/actions/Error/ErrorActions";
import { store } from "../store/index";

export const getCall = async (URL) => {
  try {
    const response = await fetch(URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
        authorization: "Bearer " + localStorage.getItem("linkly_token"),
        workspaceId: localStorage.getItem("workspaceId"),
      },
    });

    const data = await response.json();

    if (data.code === 500) {
      const err = new Error(data.message);
      err.stack = data.stack;
      err.data = data.data;
      throw err;
    }

    if (data.message === "Unauthorized") {
      if (!auth.currentUser) return data;
      const token = await auth.currentUser.getIdToken(true);
      localStorage.setItem("linkly_token", token);
      const response2 = await fetch(URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
          authorization: "Bearer " + localStorage.getItem("linkly_token"),
          workspaceId: localStorage.getItem("workspaceId"),
        },
      });

      const data2 = await response2.json();

      if (data2.code === 500) {
        const err = new Error(data2.message);
        err.stack = data2.stack;
        err.data = data2.data;
        throw err;
      }

      return data2;
    } else return data;
  } catch (error) {
    if (error instanceof SyntaxError) {
      // Unexpected token < in JSON
      console.log("There was a SyntaxError", error);
    } else {
      // window.sweet({
      //   title: "Something went wrong!",
      //   text: `Please help us resolve this. Check the logs and send it in our live chat with the screenshot.`,
      //   icon: "error",
      //   button: 'Send Logs',
      // }).then((value) => {
      //   if (value) {
      //   }
      // });
      store.dispatch(setErrorLogs(error));
    }
    throw error;
    // return error;
  }
};

export const backLinkGetCall = async (URL) => {
  try {
    const response = await fetch(URL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + localStorage.getItem("token"),
      },
    });

    const data = await response.json();
    if (data.code === 500) {
      const err = new Error(data.message);
      err.stack = data.stack;
      err.data = data.data;
      throw err;
    }

    if (data.message === "Unauthorized") {
      if (!auth2.currentUser) return data;
      const token = await auth2.currentUser.getIdToken(true);
      localStorage.setItem("token", token);
      const response2 = await fetch(URL, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      const data2 = await response2.json();

      if (data2.code === 500) {
        const err = new Error(data2.message);
        err.stack = data2.stack;
        err.data = data2.data;
        throw err;
      }

      return data2;
    } else return data;
  } catch (error) {
    if (error instanceof SyntaxError) {
      // Unexpected token < in JSON
      console.log("There was a SyntaxError", error);
    } else {
      // window
      //   .sweet({
      //     title: "Something went wrong!",
      //     text: `Please help us resolve this. Check the logs and send it in our live chat with the screenshot.`,
      //     icon: "error",
      //     button: "Send Logs",
      //   })
      //   .then((value) => {
      //     if (value) {
      //     }
      //   });
      store.dispatch(setErrorLogs(error));
    }
    return error;
  }
};
