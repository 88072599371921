// import Post from "../../../Apis/Post";
import { getCall } from "../../../Apis/Get";
import backLinkgetCall from "../../../Apis/backlink/Get";
import { getAPIUrl, getAPIUrlBM } from "../../../utils/config";

export const GET_WORK_SPACE = "GET_WORK_SPACE";

export const getWorkSpaceData = (app = "Linkly") => async (dispatch) => {
  try {
    let host, response

    if(app === "backlink"){
      host = getAPIUrlBM();
      response = await backLinkgetCall(host + "/getteams");
    }else{
      host = getAPIUrl();
      response = await getCall(host + "/v1/user/getteams");
    }

    console.log({workspaceRes : response})
    const { myUsers, workspace, currentWorkSpaceUsers, curentWorkSpacePlan, currentWorkSpaceAddOns } = response.data;
    // console.log(myUsers, workspace, "Ankur");
    dispatch({
      type: GET_WORK_SPACE,
      payload: {
        myUsers,
        workspace,
        currentWorkSpaceUsers,
        curentWorkSpacePlan,
        currentWorkSpaceAddOns
      },
    });
    // console.log(response, "Ankur chaurasia");
    // dispatch()
  } catch (error) {}
};
