export const GET_USER_LINKS = "GET_USER_LINKS";
export const GET_USER_WEBSITE_DATA = "GET_USER_WEBSITE_DATA";
export const GET_USER_ACCEPTED_LINKS = "GET_USER_ACCEPTED_LINKS";
export const GET_FILTERED_LINKS = "GET_FILTERED_LINKS";
export const GET_LINKS_COUNT = "GET_LINKS_COUNT";
export const ADD_LINKS_ONSCROLL = "ADD_LINKS_ONSCROLL";
export const INTIALIZE_STATE = "INITIAL_STATE";
export const CLEAR_STATE = "CLEAR_STATE";
export const CHANGE_STATUS = "CHANGE_STATUS";
export const LOADING = "LOADING";
