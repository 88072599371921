import { GET_WORK_SPACE } from "../../actions/userData/WorkspaceData";

const initial_state = {
  myUsers: [],
  workspace: [],
  currentWorkSpaceUsers: 0,
  curentWorkSpacePlan: "",
  currentWorkSpaceAddOns: [],
};

export const UserDataReducer = (state = initial_state, action) => {
  switch (action.type) {
    case GET_WORK_SPACE:
      return {
        ...state,
        myUsers: action.payload.myUsers,
        workspace: action.payload.workspace,
        currentWorkSpaceUsers: action.payload.currentWorkSpaceUsers,
        curentWorkSpacePlan: action.payload.curentWorkSpacePlan,
        currentWorkSpaceAddOns: action.payload.currentWorkSpaceAddOns,
      };
    default:
      return { ...state };
  }
};
