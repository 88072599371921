// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import {
  getFirebaseConfig,
  getFirebaseConfigBM,
  getSeoBoxFirebaseConfig,
} from "./utils/config";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = getFirebaseConfig();
const firebaseConfig2 = getFirebaseConfigBM();
const firebaseConfig3 = getSeoBoxFirebaseConfig();
// Initialize Firebase
const app = initializeApp(firebaseConfig);

const app2 = initializeApp(firebaseConfig2, {
  name: "backlink-monitor",
});

const app3 = initializeApp(firebaseConfig3, {
  name: "seo-box",
});

export const auth2 = getAuth(app2);
export const auth = getAuth(app);
export const auth3 = getAuth(app3);
// export const auth2 = getAuth(app2);
export const db = getFirestore(app);
export const db2 = getFirestore(app2);
export const db3 = getFirestore(app3);
export default app;

// export const auth = getAuth(app);
// connectAuthEmulator(auth, "http://localhost:8000");
// export default app;
