const sendSlackErrors = async (errorData) => {
  const user = {
    name: localStorage?.getItem("name"),
    email: localStorage?.getItem("email"),
    userid: localStorage?.getItem("userid"),
  };
  if (!errorData || errorData === null || errorData === "") return;
  // const error =
  //   typeof errorData === "object"
  //     ? JSON.stringify(errorData, null, 4)
  //     : errorData;
  const newBody = {
    user,
    error: {
      message: errorData.message,
      stack: errorData.stack,
      errorInString: JSON.stringify(errorData),
      logs: errorData.errorLogs,
    },
    logs: errorData.errorLogs,
  };
  const error = JSON.stringify(errorData);
  const text = JSON.stringify(newBody);
  const body = JSON.stringify({ text });
  console.log("body", body);
  try {
    const res = await fetch(process.env.REACT_APP_SLACK_WEBHOOK_API, {
      method: "POST",
      body,
    });
    if (res.ok) {
      console.log("Repots sent to slack");
    } else {
      //   throw new Error("Error sending message to slack");
      console.log("Error sending message to slack");
    }
  } catch (error) {
    console.log(error.message);
  }
};

export default sendSlackErrors;
