import { Backdrop, Box, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Rings } from "react-loader-spinner";
import quickTips from "../backlinkTips";
import { quickTipsForIndexMeApp } from "../backlinkTips";

const TipLoader = ({ onCompleteLoad, setTipLoader, time = 3000, product }) => {
  const [tip, setTip] = useState("");
  const Tips = product === "indexme" ? quickTipsForIndexMeApp : quickTips;
  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * Tips.length);
    setTip(Tips[randomIndex]);
  }, []);

  useEffect(() => {
    console.log({ onCompleteLoad });
    // onCompleteLoad will be called after loading is complete
    const timeout = setTimeout(() => {
      if (setTipLoader) setTipLoader(false);
      if (onCompleteLoad) onCompleteLoad();
    }, time);

    return () => clearTimeout(timeout);
  }, [onCompleteLoad, setTipLoader, time]);

  return (
    <>
      <Backdrop open sx={{ zIndex: 100, backgroundColor: "#0006" }}>
        <Paper
          elevation={0}
          sx={{
            p: 4,
            borderRadius: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: 500,
          }}
        >
          <Typography variant="h5" component="h2" fontWeight={"bold"} mb={2}>
            Loading....
          </Typography>
          <Rings
            height="80"
            width="80"
            color="#000"
            radius="8"
            wrapperStyle={{}}
            wrapperClass=""
            visible
            ariaLabel="rings-loading"
          />

          <Box sx={{ mt: 4 }}>
            <Typography variant="body1" component="span" fontWeight={"bold"}>
              Quick Tip -{" "}
            </Typography>
            <Typography variant="body1" component="span">
              {tip}
            </Typography>
          </Box>
        </Paper>
      </Backdrop>
    </>
  );
};

export default TipLoader;
