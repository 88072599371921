import { SET_ERROR_LOGS, CLEAR_ERROR_LOGS } from "../../actions/Error/ErrorActions";

const initial_state = {
    isError : false,
    errorLogs : "",
    errorData : null
};

export const ErrorReducer = (state = initial_state, action) => {
  switch (action.type) {
    case SET_ERROR_LOGS:
      const {errorLogs, errorData} = action.payload;
      return {
        ...state,
        isError : true,
        errorLogs,
        errorData : (typeof errorData === 'object')? JSON.stringify(errorData, null, 4) : errorData,
      };

    case CLEAR_ERROR_LOGS:
        return {
            ...state,
            isError : false,
            errorLogs: "",
            errorData : null
        };
    
    default:
      return { ...state };
  }
};
