import { auth } from "../firebase";
import sendSlackErrors from "../helpers/sendErrorsSlack";
import { setErrorLogs } from "../store/actions/Error/ErrorActions";
import { store } from "../store/index";

const Post = async (URL, body) => {
  try {
    const response = fetch(URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
        authorization: "Bearer " + localStorage.getItem("linkly_token"),
        workspaceId: localStorage.getItem("workspaceId"),
        chatUser: localStorage.getItem("rocketUser"),
        chatToken: localStorage.getItem("rocketChat"),
        chatusername: localStorage.getItem("chatUser"),
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        return res.json();
      })
      .then(async (res) => {
        if (res.code === 500) {
          const err = new Error(res.message);
          err.stack = res.stack;
          err.data = res.data;
          throw err;
        }

        if (res.message === "Unauthorized") {
          if (!auth.currentUser) return res;
          const token = await auth.currentUser.getIdToken(true);
          localStorage.setItem("linkly_token", token);
          const response = await fetch(URL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + localStorage.getItem("linkly_token"),
              workspaceId: localStorage.getItem("workspaceId"),
              "Cache-Control": "no-cache",
              Pragma: "no-cache",
              Expires: "0",
              chatUser: localStorage.getItem("rocketUser"),
              chatToken: localStorage.getItem("rocketChat"),
              chatusername: localStorage.getItem("chatUser"),
            },
            body: JSON.stringify(body),
          });

          const resp = await response.json();
          if (resp.code === 500) {
            const err = new Error(resp.message);
            err.stack = resp.stack;
            err.data = res.data;
            throw err;
          }
          return resp;
        } else return res;
      })
      .catch(async (err) => {
        if (err instanceof SyntaxError) {
          // Unexpected token < in JSON
          console.log("There was a SyntaxError", err);
        } else {
          // window.sweet({
          //   title: "Something went wrong!",
          //   text: `Please help us resolve this. Check the logs and send it in our live chat with the screenshot.`,
          //   icon: "error",
          //   button: 'Send Logs',
          // }).then((value) => {
          //   if (value) {
          //   }
          // });
          store.dispatch(setErrorLogs(err));
        }
        // throw err;
        return err;
      });
    return response;
  } catch (error) {
    return error;
  }
};

export default Post;
