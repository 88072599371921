import { getCall } from "../../../Apis/Get";
import Post from "../../../Apis/Post";

import { getAPIUrl } from "../../../utils/config";
import { CookiesProvider, useCookies } from "react-cookie";

export const GET_ROOM = "GET_ROOM";
export const GET_USER_DATA = "GET_USER_DATA";

export const getRoom = () => async (dispatch) => {
  try {
    const host = getAPIUrl();
    const response = await getCall(`${host}/v1/user/getRoomId`);
    dispatch({
      type: GET_ROOM,
      payload: response,
    });
  } catch (error) {
    console.log(error);
  }
};

const SaveRocketChatData = (data) => {
  try {
  } catch (error) {
    console.log(error, "Rocket Chat is going to save in the redux");
  }
};

export const getUserRocketChatData = () => async (dispatch) => {
  try {
    const host = getAPIUrl();
    const response = await Post(`${host}/v1/user/rocketChatLogin`);
    const body = response;
    localStorage.setItem("Meteor.loginToken", body.rocketToken);
    localStorage.setItem("rocketChat", body.rocketToken);
    localStorage.setItem("rocketUser", body.rocketChatUserId);
    localStorage.setItem("chatUser", body.username);

    const iframe = document.querySelector("iframe");
    console.log(iframe, "iframe");
    document.querySelector("iframe").contentWindow.postMessage(
      {
        externalCommand: "login-with-token",
        token: body.rocketToken,
        event: "login-with-token",
        loginToken: body.rocketToken,
      },
      process.env.REACT_APP_ROCKET_CHAT_URL,
    );

    SaveRocketChatData(body);
    const userData = await Post(`${host}/v1/user/getUserInfo`);
    // console.log(userData, "Rocket Chat is going to save in the redux");
    dispatch({
      type: GET_USER_DATA,
      payload: userData.data,
    });
  } catch (err) {}
};
