import { GET_ROOM, GET_USER_DATA } from "../../actions/ChatRoom/ChatRoom";

const initial_state = {
  room: {},
  unreadRooms: [],
};

export const ChatRoomReducer = (state = initial_state, action) => {
  switch (action.type) {
    case GET_ROOM:
      return {
        ...state,
        room: action.payload.response[0],
      };
    case GET_USER_DATA:
      let unreadData = action.payload.map((item) => {
        if (item.unread !== 0 && item.name !== "general")
          return {
            ...item,
            color: "#" + Math.floor(Math.random() * 16777215).toString(16),
            name: item.name === "general" ? item.name : item.name.split(",")[1],
          };
        return false;
      });
      unreadData = unreadData.filter((item) => item !== false);
      // console.log("NEW STATE BUILD", unreadData);
      return {
        ...state,
        unreadRooms: unreadData,
      };
    default:
      return { ...state };
  }
};
