const quickTips = [
  "Organise your backlinks into lists for better management.",
  "Create one list per client for better management.",
  "Share list public URLs to build trust with your clients.",
  "Upload your historical backlinks to check if some are removed.",
  "Keep your lists updated with the latest backlinks.",
  "Regularly monitor your domains for changes in backlinks.",
  "Quality backlinks are more valuable than quantity.",
  "Use anchor text wisely to optimise your link profile.",
  "Check for broken backlinks and fix them promptly.",
  "Perform competitor analysis to identify linking opportunities.",
  "Disavow spammy or low-quality backlinks to maintain your site's reputation.",
  "Focus on building relationships for natural backlink growth.",
  "Create high-quality content that naturally attracts backlinks.",
  "Monitor your backlinks before your client finds lost backlinks.",
  "Stay up-to-date with search engine algorithm changes.",
  "Diversify your backlink sources for a well-rounded profile.",
  "Monitor competitor backlinks to stay competitive.",
  "Mobile-friendly websites attract more organic backlinks.",
  "Niche directories can provide targeted and relevant backlinks.",
  "Patience is key – backlink growth takes time.",
  "Guest posting on reputable sites can earn you authoritative backlinks.",
  "Internal linking also plays a role in strengthening your backlink structure.",
  "Regularly audit your backlinks to maintain a healthy link profile.",
  "Align your backlink strategy with your overall content marketing goals.",
  "Reclaim lost backlinks by reaching out to webmasters with updated content.",
  "Set up alerts to quickly address any lost or removed backlinks.",
  "Contact webmasters politely to reclaim lost backlinks.",
  "Fix lost backlink before you lose client's trust.",
  "Regularly share backlink reports with clients to showcase SEO efforts.",
];

const quickTipsForIndexMeApp = [
  "Connect your Google Search Console to quickly get started",
  "We fetch the sitemap that you submitted in your Google Search Console",
  "We fetch all the URLs from your sitemaps & check if those backlinks are indexed",
  "Use Index My Site to bypass the 1000 URL limit of Google Search Console",
  "We Request Indexing for your unindexed URLs to Google.",
  "Index My Site is built on Google Search Console for more accuracy in Index status.",
  "Index My Site sends Request Indexing for your URLs through Google Search Console",
  "Check all the fetched URLs in the Links Activity section",
  "Check Activity logs to see anything that we do with your Google Search Console",
  "There are just domain limits in Index My Site, unlimited URLs",
  "Increase the quality of content & domain authority to get indexed faster",
  "You can improve site structure for better technical SEO",
];

export { quickTipsForIndexMeApp };

export default quickTips;
