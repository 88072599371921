export const getAPIUrl = () => {
  return process.env.REACT_APP_HOST
    ? process.env.REACT_APP_HOST
    : "http://localhost:8000/api";
};

export const getAPIUrlBM = () => {
  return process.env.REACT_APP_HOST_BM
    ? process.env.REACT_APP_HOST_BM
    : "http://localhost:8000/api/v2/backlink";
};

export const getAPIUrlSEO = () => {
  return process.env.REACT_APP_HOST_SEO
    ? process.env.REACT_APP_HOST_SEO
    : "http://localhost:8000/api/v3/seobox";
};

export const getAPIUrlChatBot = () => {
  // return "http://localhost:8000/api/v4/chatbot";
  return process.env.REACT_APP_HOST_CHATBOT
    ? process.env.REACT_APP_HOST_CHATBOT
    : "http://localhost:8000/chatbot";
};

export const getFirebaseConfig = () => {
  return {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };
};

export const getFirebaseConfigBM = () => {
  return {
    apiKey: process.env.REACT_APP_API_KEY_BM,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN_BM,
    projectId: process.env.REACT_APP_PROJECT_ID_BM,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET_BM,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_BM,
    appId: process.env.REACT_APP_APP_ID_BM,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID_BM,
  };
};

export const getSeoBoxFirebaseConfig = () => {
  return {
    apiKey: process.env.REACT_APP_SEOBOX_API_KEY,
    authDomain: process.env.REACT_APP_SEOBOX_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_SEOBOX_PROJECT_ID,
    storageBucket: process.env.REACT_APP_SEOBOX_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_SEOBOX_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_SEOBOX_APP_ID,
    measurementId: process.env.REACT_APP_SEOBOX_MEASUREMENT_ID,
  };
};

export const getFirebaseConfig4 = () => {
  return {
    apiKey: process.env.REACT_APP_CHATBOT_API_KEY,
    authDomain: process.env.REACT_APP_CHATBOT_AUTH_DOMAIN,
    // authDomain: "app.chatobot.ai",
    projectId: process.env.REACT_APP_CHATBOT_PROJECT_ID,
    storageBucket: process.env.REACT_APP_CHATBOT_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_CHATBOT_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_CHATBOT_APP_ID,
    measurementId: process.env.REACT_APP_CHATBOT_MEASUREMENT_ID,
  };
};
