import { useEffect, lazy, Suspense } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

// import Linkly from "./Linkly.App";
// import Backlink from "./Backlink.App";
// import SeoBox from "./SeoBox.App";
// import IndexMe from "./Indexme.App";

import { Helmet } from "react-helmet";
import { auth, auth2 } from "./firebase";
import { applyActionCode } from "firebase/auth";
import TipLoader from "./Backlink/utils/TipLoader/TipLoader";

const Linkly = lazy(() => import("./Linkly.App"));
const Backlink = lazy(() => import("./Backlink.App"));
const SeoBox = lazy(() => import("./SeoBox.App"));
const IndexMe = lazy(() => import("./Indexme.App"));
const ChatBot = lazy(() => import("./ChatBot.App"));

const DecideApp = ({ children }) => {
  const pathname = window.location.pathname;
  if (
    pathname === "/linkly/auth" ||
    pathname === "/backlink/auth" ||
    pathname === "/indexme/auth"
  ) {
    return (
      <>
        <div>Authentication Successful</div>
        <button
          onClick={() => {
            console.log(window.location);
            window.close();
            window.location.pathname === "/linkly/auth"
              ? window.open(window.location.origin + "/login")
              : window.open(window.location.origin + "/backlink/login");
          }}
        >
          Login
        </button>
      </>
    );
  }
  return window?.location?.pathname?.includes("/backlink/") ||
    window?.location?.pathname === "/backlink" ? (
    <Suspense fallback={<TipLoader />}>
      <Backlink>
        <Helmet>
          {/* <meta property="og:image" key="og:image" content={"/og_backLink.jpg"} /> */}
        </Helmet>
        {children}
      </Backlink>
    </Suspense>
  ) : window?.location?.pathname?.includes("/seobox/") ||
    window?.location?.pathname === "/seobox" ? (
    <Suspense fallback={<TipLoader />}>
      <SeoBox>
        <Helmet>
          <meta property="og:image" key="og:image" content={"/og_seoBox.jpg"} />
          <title>Seobox.ai</title>
          {/* Add the fav icon also */}
          <link rel="icon" href="/seoboxLogo.svg" />
          <script async defer src="https://apis.google.com/js/api.js"></script>
        </Helmet>
        {children}
      </SeoBox>
    </Suspense>
  ) : window?.location?.pathname?.includes("/indexme/") ||
    window?.location?.pathname === "/indexme" ? (
    <Suspense fallback={<TipLoader />}>
      <IndexMe>
        <Helmet>
          {/* <script async src="https://js.stripe.com/v3/pricing-table.js"></script> */}
          <script
            async
            src="https://js.stripe.com/v3/pricing-table.js"
          ></script>
          <meta
            property="og:image"
            key="og:image"
            content={"/indexmeicon.svg"}
          />
          <title>Index My Site</title>
          {/* Add the fav icon also */}
          <link rel="icon" href="/indexmeicon.svg" />
          <meta
            name="description"
            content="Use Index My Site to check if the URLs in your sitemap are indexed or not. We integrate directly with Google Search Console for the most accurate data. If any of the pages are not indexed, we send Request Indexing calls through Google Search Console instead of doing it through pinging. This is a modern way of doing things instead of the old school way. Ensure that your webpages reach Google to fetch you that SEO traffic"
          />
          {/* <meta property="og:image" key="og:image" content={"/og_backLink.jpg"} /> */}
        </Helmet>

        {children}
      </IndexMe>
    </Suspense>
  ) : window?.location?.pathname?.includes("/chatbot/") ||
    window?.location?.pathname === "/chatbot" ? (
    <Suspense fallback={<TipLoader />}>
      <ChatBot>
        <Helmet>
          <meta
            property="og:image"
            key="og:image"
            content={"/og_chatbot.jpg"}
          />
          <title>Chatbot</title>
          {/* Add the fav icon also */}
          <link rel="icon" href="/chatbotLogo.svg" />
        </Helmet>
        {children}
      </ChatBot>
    </Suspense>
  ) : (
    <Suspense fallback={<TipLoader />}>
      <Linkly>
        <Helmet>
          {/* <meta property="og:image" key="og:image" content={"/og_image.jpg"} /> */}
        </Helmet>
        {children}
      </Linkly>
    </Suspense>
  );
};

function App() {
  console.log(`Env: ${process.env.REACT_APP_NODE_ENV}`);
  const searchParams = new URLSearchParams(window.location.search);
  useEffect(() => {
    const oobCode = searchParams.get("oobCode");
    if (oobCode) {
      applyActionCode(
        window.location.pathname === "/linkly/auth" ? auth : auth2,
        oobCode,
      )
        .then((res) => {
          console.log(res, "res");
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
  }, []);

  return (
    <>
      <DecideApp>
        <ToastContainer />
      </DecideApp>
    </>
  );
}

export default App;
