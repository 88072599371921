import {
  GET_USER_ACCEPTED_LINKS,
  GET_FILTERED_LINKS,
  ADD_LINKS_ONSCROLL,
  INTIALIZE_STATE,
  CHANGE_STATUS,
  LOADING,
  CLEAR_STATE,
} from "../../actions/Manager/ActionsManagerTypes";

const initialState = {
  links: [],
  currentFilteredLinks: [],
  currentCount: 0,
  totalCount: 0,
  inProgressCount: 0,
  reWorkCount: 0,
  submittedCount: 0,
  linkCreatedCount: 0,
  loading: false,
};

export const ReducersManager = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_USER_ACCEPTED_LINKS:
      try {
        return { ...state, links: [...state.links, ...action.payload] };
      } catch (err) {
        console.log(err);
      }
      break;
    case GET_FILTERED_LINKS:
      return { ...state, currentFilteredLinks: action.payload };

    case ADD_LINKS_ONSCROLL:
      return {
        ...state,
        currentFilteredLinks: [
          ...state.currentFilteredLinks,
          ...action.payload,
        ],
      };
    case CHANGE_STATUS:
      const { linkId, status } = action.payload;
      let currentStatus = "";
      const newData = state.links.map((ele) => {
        if (ele.Link_Id === linkId) {
          currentStatus = ele.status;
          return {
            ...ele,
            status: status,
          };
        }
        return ele;
      });

      return {
        ...state,
        links: newData,
        inProgressCount:
          currentStatus === "In progress"
            ? state.inProgressCount - 1
            : status === "In progress"
            ? state.inProgressCount + 1
            : state.inProgressCount,
        reWorkCount:
          currentStatus === "Re-work"
            ? state.reWorkCount - 1
            : status === "Re-work"
            ? state.reWorkCount + 1
            : state.reWorkCount,
        submittedCount: currentStatus?.includes("Submit")
          ? state.submittedCount - 1
          : status?.includes("Submit")
          ? state.submittedCount + 1
          : state.submittedCount,
        linkCreatedCount:
          currentStatus === "Link Created"
            ? state.linkCreatedCount - 1
            : status === "Link Created"
            ? state.linkCreatedCount + 1
            : state.linkCreatedCount,
      };
    case INTIALIZE_STATE:
      return { ...state, ...action.payload };
    case CLEAR_STATE:
      return {
        ...state,
        links: [],
        currentFilteredLinks: [],
        currentCount: 0,
        totalCount: 0,
        inProgressCount: 0,
        reWorkCount: 0,
        submittedCount: 0,
        linkCreatedCount: 0,
        loading: false,
      };
    default:
      return { ...state };
  }
};
