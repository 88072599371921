import { combineReducers } from "redux";
import { ReducersManager } from "../reducers/Manager/ReducersManager";
import { UserDataReducer } from "./UserData/UserDataReducer";
import { ChatRoomReducer } from "./ChatRoom/ChatRoomReducer";
import { ErrorReducer } from "./Error/ErrorReducer";

const combinedreducers = combineReducers({
  links: ReducersManager,
  userData: UserDataReducer,
  chatRoom: ChatRoomReducer,
  errorData : ErrorReducer
});

export default combinedreducers;
